export default {
  none: 'none',
  sm: {
    x: 0,
    y: 1,
    blur: 3,
    opacity: '15%',
  },
  md: {
    x: 0,
    y: 5,
    blur: 10,
    opacity: '15%',
  },
  lg: {
    x: 0,
    y: 15,
    blur: 25,
    opacity: '15%',
  },
  inner: {
    x: 2,
    y: 9,
    blur: 20,
    opacity: '15%',
  },
}
