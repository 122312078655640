/**
 * Theme Spacing
 */

export default {
  '4xs': '0.125rem', //2px
  '3xs': '0.25rem', //4px
  '2xs': '0.5rem', //8px
  xs: '0.75', //12px
  s: '1rem', //16px
  m: '1.5rem', //24px
  l: '2rem', //32px
  '2l': '2.25rem', //36px
  xl: '2.5rem', //40px
  '2xl': '3rem', //48px
  '3xl': '3.5rem', //56px
  layoutl: '4rem', //64px
  layout2l: '4.25rem', //68px 
  layoutxl: '6rem', //96px
  layout2xl: '8rem', //128px
  layout3xl: '10.3125rem', //165px | general container padding
  containerMaxWidth: '90rem', //1440px | general container max-width
}
