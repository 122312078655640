/**
 * Theme Border Radius
 */

 export default {
  none: 'none',
  sm: '8px',
  md: '16px',
  lg: '24px',
  pill: '200px', // 200px considerando que a altura máxima não passe disso
  circle: '200px', // 200px considerando que a altura máxima não passe disso
}
