/**
 * Theme theme gradient colors
 */
export default {
  primary: {
    from: '#DF1098 14.39%',
    to: '#BA17E2 84.69%',
    degree: 105.43,
  },
  secondary: {
    from: '#018383 14.39%',
    to: '#014687 84.69%',
    degree: 105.43,
  },
}
