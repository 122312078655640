import {
  shadow,
  palette,
  gradient,
  borderRadius,
  opacityScale,
  spacing,
  fontFamily,
  fontSize,
  fontWeight,
  letterSpacing,
  lineHeight,
  headline,
  text,
  spacingInline,
  spacingStack,
  spacingSquish,
  spacingInset,
  colors,
  background,
  border,
} from './tokens'

const theme = {
  palette,
  gradient(color) {
    const { degree, from, to } = gradient[color]
    return `linear-gradient(${degree}deg, ${from}, ${to})`
  },
  shadow,
  borderRadius,
  opacityScale,
  spacing,
  fontFamily,
  fontSize,
  fontWeight,
  letterSpacing,
  lineHeight,
  headline,
  text,
  spacingInline,
  spacingStack,
  spacingSquish,
  colors,
  spacingInset,
  background,
  border
}

export default theme;