export default {
  fontSize: {
    fontSizeXS: '0.75rem', //12px
    fontSizeSM: '0.875rem', //14px
    fontSizeBase: '1rem', //16px
    fontSizeLG: '1.125rem', //18px
    fontSizeXL: '1.5rem', //24px
    fontSize2xL: '2rem', //32px
    fontSize3xL: '2.5rem', //40px
    fontSize4xL: '3rem', //48px
  },
}
