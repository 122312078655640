import {
  fontFamily,
  fontWeight,
  fontSize,
  letterSpacing,
  lineHeight,
} from './index'

export default {
  textXl: {
    fontFamily: {
      fontFamily: { fontPrimary: fontFamily.fontFamily.fontPrimary },
    },
    fontWeight: {
      fontWeight: { fontWeightBold: fontWeight.fontWeight.fontWeightBold },
    },
    fontSize: {
      fontSize: { fontSizeLG: fontSize.fontSize.fontSizeLG },
    },
    letterSpacing: {
      letterSpacing: {
        trackingTight: letterSpacing.letterSpacing.trackingTight,
      },
    },
    lineHeight: {
      lineHeight: {
        lineHeightNormal: lineHeight.lineHeight.lineHeightNormal,
      },
    },
  },
  textBaseHighlight: {
    fontFamily: {
      fontFamily: { fontPrimary: fontFamily.fontFamily.fontPrimary },
    },
    fontWeight: {
      fontWeight: { fontWeightBold: fontWeight.fontWeight.fontWeightBold },
    },
    fontSize: {
      fontSize: { fontSizeBase: fontSize.fontSize.fontSizeBase },
    },
    letterSpacing: {
      letterSpacing: {
        trackingTight: letterSpacing.letterSpacing.trackingTight,
      },
    },
    lineHeight: {
      lineHeight: {
        lineHeightNormal: lineHeight.lineHeight.lineHeightNormal,
      },
    },
  },
  textBase: {
    fontFamily: {
      fontFamily: { fontPrimary: fontFamily.fontFamily.fontPrimary },
    },
    fontWeight: {
      fontWeight: { fontWeightNormal: fontWeight.fontWeight.fontWeightNormal },
    },
    fontSize: {
      fontSize: { fontSizeBase: fontSize.fontSize.fontSizeBase },
    },
    letterSpacing: {
      letterSpacing: {
        trackingTight: letterSpacing.letterSpacing.trackingTight,
      },
    },
    lineHeight: {
      lineHeight: {
        lineHeightNormal: lineHeight.lineHeight.lineHeightNormal,
      },
    },
  },
  textSmHighlight: {
    fontFamily: {
      fontFamily: { fontPrimary: fontFamily.fontFamily.fontPrimary },
    },
    fontWeight: {
      fontWeight: { fontWeightMedium: fontWeight.fontWeight.fontWeightMedium },
    },
    fontSize: {
      fontSize: { fontSizeSM: fontSize.fontSize.fontSizeSM },
    },
    letterSpacing: {
      letterSpacing: {
        trackingTight: letterSpacing.letterSpacing.trackingTight,
      },
    },
    lineHeight: {
      lineHeight: {
        lineHeightNormal: lineHeight.lineHeight.lineHeightNormal,
      },
    },
  },
  textSm: {
    fontFamily: {
      fontFamily: { fontPrimary: fontFamily.fontFamily.fontPrimary },
    },
    fontWeight: {
      fontWeight: { fontWeightNormal: fontWeight.fontWeight.fontWeightNormal },
    },
    fontSize: {
      fontSize: { fontSizeSM: fontSize.fontSize.fontSizeSM },
    },
    letterSpacing: {
      letterSpacing: {
        trackingTight: letterSpacing.letterSpacing.trackingTight,
      },
    },
    lineHeight: {
      lineHeight: {
        lineHeightNormal: lineHeight.lineHeight.lineHeightNormal,
      },
    },
  },
  textXsHighlight: {
    fontFamily: {
      fontFamily: { fontPrimary: fontFamily.fontFamily.fontPrimary },
    },
    fontWeight: {
      fontWeight: { fontWeightBold: fontWeight.fontWeight.fontWeightBold },
    },
    fontSize: {
      fontSize: { fontSizeXS: fontSize.fontSize.fontSizeXS },
    },
    letterSpacing: {
      letterSpacing: {
        trackingTight: letterSpacing.letterSpacing.trackingTight,
      },
    },
    lineHeight: {
      lineHeight: {
        lineHeightNormal: lineHeight.lineHeight.lineHeightNormal,
      },
    },
  },
  textXs: {
    fontFamily: {
      fontFamily: { fontPrimary: fontFamily.fontFamily.fontPrimary },
    },
    fontWeight: {
      fontWeight: { fontWeightNormal: fontWeight.fontWeight.fontWeightNormal },
    },
    fontSize: {
      fontSize: { fontSizeXS: fontSize.fontSize.fontSizeXS },
    },
    letterSpacing: {
      letterSpacing: {
        trackingTight: letterSpacing.letterSpacing.trackingTight,
      },
    },
    lineHeight: {
      lineHeight: {
        lineHeightNormal: lineHeight.lineHeight.lineHeightNormal,
      },
    },
  },
}
