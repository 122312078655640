export default {
  lineHeight: {
    lineHeightBase: '1em',
    lineHeightTight: '1.2em',
    lineHeightNormal: '1.5em',
    lineHeightRelaxed: '1.7em',
    lineHeightMedium: '2.25em',
    lineHeightLarge: '3.75em'
  },
}
