import {
  fontFamily,
  fontWeight,
  fontSize,
  letterSpacing,
  lineHeight,
} from './index'

export default {
  headlineXl: {
    fontFamily: {
      fontFamily: { fontPrimary: fontFamily.fontFamily.fontPrimary },
    },
    fontWeight: {
      fontWeight: { fontWeightBold: fontWeight.fontWeight.fontWeightBold },
    },
    fontSize: {
      fontSize: { fontSize4xL: fontSize.fontSize.fontSize4xL },
    },
    letterSpacing: {
      letterSpacing: {
        trackingTight: letterSpacing.letterSpacing.trackingTight,
      },
    },
    lineHeight: {
      lineHeight: {
        lineHeightTight: lineHeight.lineHeight.lineHeightTight,
      },
    },
  },
  headlineLg: {
    fontFamily: {
      fontFamily: { fontPrimary: fontFamily.fontFamily.fontPrimary },
    },
    fontWeight: {
      fontWeight: { fontWeightBold: fontWeight.fontWeight.fontWeightBold },
    },
    fontSize: {
      fontSize: { fontSize3xL: fontSize.fontSize.fontSize3xL },
    },
    letterSpacing: {
      letterSpacing: {
        trackingTight: letterSpacing.letterSpacing.trackingTight,
      },
    },
    lineHeight: {
      lineHeight: {
        lineHeightTight: lineHeight.lineHeight.lineHeightTight,
      },
    },
  },
  headlineBase: {
    fontFamily: {
      fontFamily: { fontPrimary: fontFamily.fontFamily.fontPrimary },
    },
    fontWeight: {
      fontWeight: { fontWeightBold: fontWeight.fontWeight.fontWeightBold },
    },
    fontSize: {
      fontSize: { fontSize2xL: fontSize.fontSize.fontSize2xL },
    },
    letterSpacing: {
      letterSpacing: {
        trackingTight: letterSpacing.letterSpacing.trackingTight,
      },
    },
    lineHeight: {
      lineHeight: {
        lineHeightTight: lineHeight.lineHeight.lineHeightTight,
      },
    },
  },
  headlineSm: {
    fontFamily: {
      fontFamily: { fontPrimary: fontFamily.fontFamily.fontPrimary },
    },
    fontWeight: {
      fontWeight: { fontWeightNormal: fontWeight.fontWeight.fontWeightNormal },
    },
    fontSize: {
      fontSize: { fontSizeXL: fontSize.fontSize.fontSizeXL },
    },
    letterSpacing: {
      letterSpacing: {
        trackingTight: letterSpacing.letterSpacing.trackingTight,
      },
    },
    lineHeight: {
      lineHeight: {
        lineHeightNormal: lineHeight.lineHeight.lineHeightNormal,
      },
    },
  },
  headlineSmHighlight: {
    fontFamily: {
      fontFamily: { fontPrimary: fontFamily.fontFamily.fontPrimary },
    },
    fontWeight: {
      fontWeight: { fontWeightBold: fontWeight.fontWeight.fontWeightBold },
    },
    fontSize: {
      fontSize: { fontSizeXL: fontSize.fontSize.fontSizeXL },
    },
    letterSpacing: {
      letterSpacing: {
        trackingTight: letterSpacing.letterSpacing.trackingTight,
      },
    },
    lineHeight: {
      lineHeight: {
        lineHeightNormal: lineHeight.lineHeight.lineHeightNormal,
      },
    },
  },
}
