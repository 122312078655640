/**
 * Theme Spacing
 */

 export default {
  xxs: '0.25rem',
  xs: '0.5rem',
  m: '1rem',
  xm: '1.5rem',
  xxm: '2rem',
  l: '3rem',
  xl: '4rem',
}
