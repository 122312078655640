/**
 * Theme Spacing Insert
 */

 export default {
    xxs: '2px',
    xs: '4px',
    s: '8px',
    m: '16px',
    xm: '24px',
    xxm: '32px',
    l: '48px'
}
