/**
 * Theme Opacity Levels
 */

 export default {
  none: '1',
  sm: '0.9',
  md: '0.7',
  lg: '0.5',
  xl: '0.3',
}
