/**
 * The theme palette
 */
 export default {
  primary: {
    main: '#DF1098',
    variations: ['#B40478'],
  },
  secondary: {
    main: '#018762',
    variations: ['#007756'],
  },
  error: {
    main: '#BC1C1C',
    variations: ['#FB2759', '#FE688C', '#FBA7BB', '#FBD0DA'],
  },
  warning: {
    main: '#FFCC00',
  },
  info: {
    main: '#29598C',
    variations: ['#3779BF', '#0072EC', '#6EB1FA', '#C8E0FA'],
  },
  success: {
    main: '#007756',
    variations: ['#36B390', '#29CC9F', '#17E6AC', '#F3FFF9'],
  },
  grayscale: {
    main: '#1F1F1F',
    variations: ['#220F1A', '#515151', '#CFCFCF', '#EEEEEE'],
  },
  purple: {
    main: '#5A298C',
    variations: ['#7B37BF', '#7C17E2', '#B46EFA', '#E7D4FA'],
  },
  white: {
    main: '#FFFFFF',
  },
  black: {
    main: '#220F1A',
  },
  alert: {
    dark: '#AC4022',
  },
}
