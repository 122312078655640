export default {
  primary: {
    pink: "#DF1098",
    purple: "#7C17E2",
  },

  secondary: {
    purple: "#E3C7FF",
    health_green: "#018762",
    txt_green: "#018383",
  },

  auxiliary: {
    black: "#220F1A",
    link: "#0072EC",
    alert: "#EC0E00",
    pink: "#FCD7ED",
    green: "#B0E0D3",
  },

  background: "#FFFFFF",

  gradient: {
    gradient: {
      pink: "linear-gradient(105.43deg, #DF1098 14.39%, #BA17E2 73.71%)",
      green: "linear-gradient(105.43deg, #018383 14.39%, #014687 84.69%)",
      background: "linear-gradient(105.43deg, #FFFFFF 14.39%, #F9F3FF 84.69%)",
    },
  },
  gray: {
    dark: "#515151",
    mid: "#6a6a6a",
    light: "#F4F4F4",
    text_base: "#1F1F1F",
    disable: "#CFCFCF",
    basic: "#E6EEF8"
  },

  white: {
    main: "#FFFFFF",
  },
  black: {
    main: "#220F1A",
  },
  success: {
    main: "#0B854A",
  },
  alert: {
    main: "#C75126",
  },
  error: {
    main: "#BC1C1C",
  },
  info: {
    main: "#29598C",
  },
  blue: {
    700: "#0072EC",
    600: "#3779BF",
    500: "#29598C",
    400: "#3779BF",
    300: "#0072EC",
    200: "#6EB1FA",
    100: "#6EB1FA",
  },
  purple: {
    700: "#7B37BF",
    600: "#5A298C",
    500: "#7B37BF",
    400: "#7C17E2",
    300: "#B46EFA",
    200: "#E7D4FA",
    100: "#F9F3FF",
  },
  green: {
    900: "#295146",
    700: "#007756",
    600: "#007756",
    500: "#BC1C1C",
    400: "#FB2759",
    300: "#FE688C",
    200: "#FBA7BB",
    100: "#C3E9DE",
  },
  red: {
    700: "#007756",
    600: "#007756",
    500: "#BC1C1C",
    400: "#FB2759",
    300: "#FE688C",
    200: "#FBA7BB",
    100: "#FBD0DA",
  },
  pink: {
    700: "#650042",
    600: "#A3096E",
    500: "#C40E86",
    400: "#DF1098",
    300: "#FF4DC2",
    200: "#F78DCE",
    100: "#FFC2EA",
  },
  text: {
    heading: "#1F1F1F",
    base: "#2D2D2D",
    disabled: "#515151",
  },
}
