import { createGlobalStyle } from "styled-components"

export default createGlobalStyle`
*, *::before, *::after {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: ${({ theme }) => theme.colors.background};
  overflow-x: hidden;
}

body, input, textarea, button {
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
}

h1, h2, h3, h4, h5, h6 {
  margin:0;
  padding:0;
}


ul {
  list-style: none;
}

a {
  text-decoration: none;
}

button {
  border: none;
  background: none;
  cursor: pointer;
}

container {
  display: flex;
  width: 100%;
  padding: 1rem 6rem;
}
`
